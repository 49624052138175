<template>
  <div id="nationalSign">
    <div class="header">
      <img
        src="https://activity-resource.321mh.com/nft/notice/national_sign_1.png"
        alt=""
        srcset=""
      />
      <div class="detail" @click="toDetails">活动详情</div>
    </div>

    <div
      style="background: linear-gradient(180deg, #431fa6 0%, #250c69 100%); padding-bottom: 27px"
    >
      <div class="signContainer">
        <!-- 未开始 -->
        <div class="signList" v-if="cur_day == 0">
          <div class="signItem" v-for="(val, key, i) in nationalList" :key="i">
            <img class="signBg" v-if="i < 6" src="../imgs/activity/nextSign.png" alt="" srcset="" />
            <div v-if="i < 6" class="signBgModel"></div>
            <!-- 第七天的背景 -->
            <img
              v-if="i == 6"
              class="signBg7"
              src="../imgs/activity/nextSign7.png"
              alt=""
              srcset=""
            />
            <!-- 第七天未签到遮罩 -->
            <div v-if="i == 6" class="signBgModel signBgModel7"></div>

            <img class="signIcon" src="../imgs/activity/boxIcon.png" alt="" srcset="" />

            <div class="date">10月{{ i + 1 }}日</div>
          </div>
        </div>

        <!-- 开始 -->
        <div class="signList" v-else>
          <div class="signItem" v-for="(val, key, i) in nationalList" :key="i">
            <img class="signIcon" src="../imgs/activity/boxIcon.png" alt="" srcset="" />
            <!-- 未签到 已过签到时间 -->
            <img
              class="signBg"
              v-if="i < 6 && !val && cur_day > i + 1"
              src="../imgs/activity/overSign.png"
              alt=""
              srcset=""
            />
            <div v-if="i < 6 && !val && cur_day > i + 1" class="signBgModel"></div>

            <!-- 已签到 已过签到当天 -->
            <img
              class="signBg"
              v-if="i < 6 && val && cur_day > i + 1"
              src="../imgs/activity/nextSign.png"
              alt=""
              srcset=""
            />

            <!-- 签到当天（已签到，未签到） -->
            <img
              class="signBg"
              v-if="i < 6 && cur_day == i + 1"
              src="../imgs/activity/todaySign.png"
              alt=""
              srcset=""
            />

            <!-- 未签到，未到签到时间 -->
            <img
              v-if="i < 6 && cur_day < i + 1"
              class="signBg"
              :src="
                cur_day == -1
                  ? require('../imgs/activity/overSign.png')
                  : require('../imgs/activity/nextSign.png')
              "
              alt=""
              srcset=""
            />
            <div v-if="i < 6 && cur_day < i + 1 && !val" class="signBgModel"></div>

            <!-- 第七天的背景 -->
            <img
              v-if="i == 6 && cur_day !== 7"
              class="signBg7"
              :src="
                cur_day == -1
                  ? require('../imgs/activity/overSign7.png')
                  : require('../imgs/activity/nextSign7.png')
              "
              alt=""
              srcset=""
            />
            <!-- 第七天未签到遮罩 -->
            <div v-if="i == 6 && cur_day !== 7 && !val" class="signBgModel signBgModel7"></div>

            <!-- 第七天当天 -->
            <img
              v-if="i == 6 && cur_day == 7"
              class="signBg7"
              src="../imgs/activity/todaySign7.png"
              alt=""
              srcset=""
            />

            <!-- 光圈背景 签到了才有 或者是当天 -->
            <img
              v-if="val || cur_day == i + 1"
              class="bright"
              src="../imgs/activity/bright.png"
              alt=""
              srcset=""
            />
            <van-icon class="checked" size="0.48rem" v-if="val" :name="checkedIcon" />
            <div class="date">10月{{ i + 1 }}日</div>
          </div>
        </div>

        <div class="awards">
          你将获得<span>{{ rewardNum }}份</span>国庆限定盲盒
        </div>

        <div class="btnStatus">
          <div class="disabledBtn" v-if="cur_day == 0">活动未开始</div>
          <div class="disabledBtn" v-else-if="cur_day == -1">活动已结束</div>
          <div
            class="signBtn"
            @click="signHandle"
            v-else-if="cur_day >= 1 && cur_day <= 7 && !nationalList['day' + cur_day]"
          >
            立即签到
          </div>
          <div
            class="signBtnEd"
            v-else-if="cur_day >= 1 && cur_day <= 7 && nationalList['day' + cur_day]"
          >
            已签到
          </div>
        </div>
      </div>

      <div class="rule">
        <div class="ruleBox">
          <div>活动规则：</div>
          <div>
            凡在看漫数藏平台实名注册的用户，活动期间（2022年10月1日00:00 -
            2022年10月7日23:59）点击平台首页—“国庆签到有礼”悬浮按钮进入页面，即可参与“签到有礼”活动。
          </div>
          <div>每人每日一次签到机会，00:00签到更新。每签到一次，可获得1份「国庆限定盲盒」。</div>
        </div>
      </div>

      <div class="rule">
        <div class="ruleBox">
          <div>活动说明：</div>
          <div>
            1、「<span class="underline">国庆限定盲盒</span>」会在10月8日16:00
            根据签到获得的盲盒数量，陆续空投；
          </div>
          <div>2、空投的盲盒会在“<span class="underline">我的 - 藏品</span>”里面展示。</div>
        </div>
      </div>
    </div>

    <!-- 签到成功弹窗 -->
    <van-popup class="vantPop" v-model:show="signShow" @close="knowHandle">
      <div class="signSuccess">
        <div class="title">恭喜你将获得1份国庆限定盲盒</div>
        <div class="box">
          <img class="bigBright" src="../imgs/activity/bigBright.png" alt="" />
          <img class="boxIcon" src="../imgs/activity/boxIcon.png" alt="" />
        </div>
        <div class="know" @click="knowHandle">知道了</div>
        <div class="time">盲盒将于10月8日16:00 陆续空投，请注意查收</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { nftUtils } from '@/utils';
import { useRouter } from 'vue-router';
import { getsignstat, sign } from '@/service/national';

export default {
  name: 'nationalSign',
  setup() {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      nationalList: [],
      cur_day: 0,
      signShow: false,
      rewardNum: 0,
      isLogin: nftUtils.isLogin(),
      sign_day: ''
    });

    onMounted(() => {
      getsign();
    });

    const getsign = () => {
      getsignstat({
        // sign_day: state.sign_day
      }).then(res => {
        if (res.status == 0) {
          state.rewardNum = 0;
          state.nationalList = res.data.sign_data;
          state.cur_day = res.data.cur_day;
          // state.cur_day = 0;
          for (let i in state.nationalList) {
            if (state.nationalList[i]) {
              state.rewardNum++;
            }
          }
          // state.nationalList = { day1: 1, day2: 0, day3: 1, day4: 0, day5: 1, day6: 1, day7: 0 };
          // state.cur_day = 7;
        }
        // console.log(res);
      });
    };

    const signHandle = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }
      sign({
        // sign_day: state.sign_day
      }).then(res => {
        if (res.status == 0) {
          state.signShow = true;
        } else {
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };

    const knowHandle = () => {
      state.signShow = false;
      getsign();
    };

    const toDetails = () => {
      router.push({ path: '/announcement/detail', query: { id: 36 } });
    };

    return {
      getsign,
      signHandle,
      knowHandle,
      toDetails,
      checkedIcon: require('../imgs/activity/checked.png'),
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
.signSuccess {
  width: 286px;
  height: 278px;
  background-image: url('https://activity-resource.321mh.com/nft/notice/national_sign_success.png');
  background-size: contain;
  > div {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    top: 45px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }
  .box {
    top: 47px;
    position: relative;
    font-size: 0;

    .bigBright {
      width: 166px;
      height: 166px;
    }

    .boxIcon {
      position: absolute;
      width: 106px;
      height: 106px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .know {
    top: 189px;
    margin: 0 auto;
    width: 116px;
    height: 36px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: #1c172a;
    line-height: 36px;
    background: linear-gradient(130deg, #ffee54 0%, #ff9152 100%);
    box-shadow: inset 0px 4px 4px 0px #ffec86;
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
  }

  .time {
    top: 237px;
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    line-height: 12px;
  }
}
#nationalSign {
}
.header {
  position: relative;
  font-size: 0;
  img {
    width: 100%;
  }

  .detail {
    position: absolute;
    right: 0;
    top: 11px;
    text-align: center;
    border-radius: 14px 0 0 14px;
    width: 64px;
    height: 24px;
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
}
.signContainer {
  margin: 0 auto;
  width: 342px;
  height: 331px;
  background-image: url('https://activity-resource.321mh.com/nft/notice/national_sign_bg.png');
  background-size: contain;

  .signList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 21px;
  }

  .signItem {
    position: relative;
    font-size: 0;
    width: 25%;
    text-align: center;
    margin-bottom: 12px;

    .signIcon {
      position: absolute;
      z-index: 100;
      width: 56px;
      height: 56px;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
    }

    .signBg {
      width: 78px;
    }

    .signBg7 {
      width: 164px;
    }

    .date {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 14px;
      position: absolute;
      width: 100%;
      bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
    }

    .bright {
      width: 70px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .signBgModel {
      position: absolute;
      z-index: 100;
      width: 78px;
      top: 0;
      left: 50%;
      border-radius: 4px 4px 4px 4px;
      transform: translateX(-50%);
      height: 100%;
      background: #2d0066;
      opacity: 0.5;
    }

    .signBgModel7 {
      width: 164px;
    }

    .checked {
      position: absolute;
      right: 8px;
      top: 4px;
    }
  }

  .signItem:last-child {
    width: 50%;
  }

  .awards {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;

    span {
      font-size: 16px;
      color: #ffbf1c;
      font-weight: 500;
    }
  }

  .btnStatus {
    margin-top: 12px;

    > div {
      margin: 0 auto;
      width: 204px;
      height: 44px;
      line-height: 44px;
      border-radius: 30px 30px 30px 30px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
    .disabledBtn {
      background: linear-gradient(132deg, #6c54ff 0%, #8c70ff 100%);
      color: #3f0e7d;
    }
    .signBtn {
      background: linear-gradient(130deg, #ffee54 0%, #ff9152 100%);
      box-shadow: inset 0px 4px 4px 0px #ffec86;
      color: #333333;
    }

    .signBtnEd {
      background: linear-gradient(130deg, #ffee54 0%, #ff9152 100%);
      box-shadow: inset 0px 4px 4px 0px #ffec86;
      color: #333333;
      opacity: 0.6;
    }
  }
}

.rule {
  width: 342px;
  margin: 0 auto;
  margin-top: 16px;
  background: #43249b;
  border-radius: 8px 8px 8px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;

  .ruleBox {
    padding: 10px;

    > div {
      margin-bottom: 6px;
    }

    > div:last-child {
      margin-bottom: 0;
    }

    .underline {
      color: #ffbf1c;
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss">
// html,
// body {
//   background-color: #250c69;
// }
#nationalSign {
  .vantPop {
    background: transparent !important;
  }
}
</style>
