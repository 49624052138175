import { http } from '@/api';

// 获取（2022国庆）签到状态
export function getsignstat(params) {
  return http.get('/v1/nationalsign/getsignstat', params);
}

// （2022国庆）签到
export function sign(params) {
  return http.post('/v1/nationalsign/sign', params);
}









